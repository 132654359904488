import { useRouter } from 'next/router';
import { CustomPageId } from '../../../../contentful/routes';

// Some pages have a custom back button that needs to be mapped to a url and title
const backButtonMapper: Record<string, { url: string; title: string }> = {
	[CustomPageId.ParkingReservations]: {
		url: `/account/parking-reservations`,
		title: 'Parking Reservations',
	},
	[CustomPageId.RetrieveBooking]: {
		url: `/park/booking/retrieve`,
		title: 'Back',
	},
};

const OVERRIDES = [
	{
		url: 'park/booking/retrieve',
		backLink: '/park',
		backLinkText: 'Park',
	},
];

/**
 * Since we found that we needed to override the back button on some pages,
 * we created this utility function to handle the logic for us.
 *
 * This utility function is used to determine the back button url and title
 * for a given page. It will return the default back button if no custom one
 * is found.
 */
export const useBackButtonLogic = (backLink: string, backLinkText: string) => {
	const { query, pathname } = useRouter();
	const fromPage = query.fromPage as string;

	let backButtonOverride;
	// Override the back button if we're on the retrieve booking page
	const foundOverride = OVERRIDES.find(({ url }) => pathname.includes(url));

	if (foundOverride) {
		backButtonOverride = {
			url: foundOverride.backLink,
			title: foundOverride.backLinkText,
		};
	}

	// Override the back button if we have a custom one
	// Because we can use the same page, but accessed by different routes, we need to check the query param
	// to determine which back button to use
	return (
		backButtonOverride ??
		backButtonMapper[fromPage] ?? {
			url: backLink,
			title: backLinkText,
		}
	);
};
